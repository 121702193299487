import React from 'react';

const Pagination = ({mailbox, setPage}:{mailbox:any, setPage:any}) => {
  let pageItems;

  if(mailbox && mailbox.links) {
    const currentPage = mailbox.current_page;
    const pageLinks = mailbox.links;

    const setCPage = (page: string) => {
      if (page) {
        const tmp = page.split("page=");
        if(tmp[1] !== currentPage)
          setPage(tmp[1]);
      }
    }

    pageItems = pageLinks.map((item: any, index: any) => {
        return (
          <button type="button" key={index} className={(currentPage === index)?"active":((!item.url)?"disable":"")} onClick={(event: any) => {
            setCPage(item.url)}}>
            {(item.label === "&laquo; Previous")?"Prev":((item.label === "Next &raquo;")?"Next":item.label)}
          </button>
        )
    });
}
  return (
    <div className="paggination">
      {pageItems}
    </div>
  );
};

export default Pagination;
