import React, { useState, useEffect } from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { useBetween } from 'use-between';

import Api from './api';

import './assets/scss/App.scss';
import ScrollToTop from './components/scrolltotop';
import NavBar from './components/nav-bar';
import MobileMenu from './components/mobile-menu';
import Result from './pages/result';
import SearchPage from './pages/search-page';

const App = () => {
  const [asLoaded, setAsLoaded] = useState(false);
  const [tcLoaded, setTcLoaded] = useState(false);
  const [brandId, setBrandId] = useState();
  const { brandsWithSearchCount, brands, fetchBrandsWithSearchCount, fetchBrands } = useBetween(Api);
  const [showMobileMenu, setShowMobileMenu] = useState(false);

  useEffect(() => {
    if(brandsWithSearchCount.length === 0)
      fetchBrandsWithSearchCount("",setTcLoaded);
  }, [brandsWithSearchCount, fetchBrandsWithSearchCount])

  useEffect(() => {
    if(brands.length === 0)
      fetchBrands(setAsLoaded);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [brands, fetchBrands])

  return (
    <div>
      <NavBar showMobileMenu={showMobileMenu} setShowMobileMenu={setShowMobileMenu} />
      <MobileMenu popular={brandsWithSearchCount} brands={brands} brandId={brandId} show={showMobileMenu}/>
      <BrowserRouter>
        <ScrollToTop />
        <Routes>
          <Route path="/" element={<SearchPage asLoaded={asLoaded} setAsLoaded={setAsLoaded} tcLoaded={tcLoaded} setTcLoaded={setTcLoaded} />}/>
          <Route path="/brands/:brand_id" element={<Result asLoaded={asLoaded} setAsLoaded={setAsLoaded} setBrandId={setBrandId} />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
};

export default App;