const MobileMenu = ({popular, brands, brandId, show}:{popular: any, brands:any, brandId: any, show: boolean}) => {
    // eslint-disable-next-line
    let popularItems, relatedItems, brand_name;
    brand_name = "";
    
    function escapeRegexCharacters(str: string): string {
        return str.replace(/[.*+?^${}()|[\]\\]/g, "\\$&");
    }

    function getSuggestions(value: string, items: any): string[] {
        const escapedValue = escapeRegexCharacters(value.trim());

        if (escapedValue === "") {
            return [];
        }

        return items.sort((a:any,b:any) => {
            a = a.name.toLowerCase();
            b = b.name.toLowerCase();
            let an = a.indexOf(value.toLowerCase());
            let bn = b.indexOf(value.toLowerCase());

            if(an === bn)
                return (a.name > b.name) ? -1 : ((b.name > a.name) ? 1 : 0);
            else
                return  an > bn ? -1 : 1;
        }); 
    }

    function getRelatedData(brand_name:string, data:any) {
        const arrStr = brand_name.split("");
        let str = ""
        arrStr.forEach((value, index) => {
            str += value;
            data = getSuggestions(str, data);
        })

        return data;
    }

    if(popular.data) {
        const popular_data = popular.data;
        popularItems = popular_data.map((item: any, index: number) => {
            if(brandId) {
                if(item.id === Number(brandId)) {
                    brand_name = item.name;
                }
            }
            if (index < 4)
                return (
                    <li key={index}>
                        <a href={"/brands/"+item.id}>
                            <img src={item.image_url} alt={item.name} />
                        </a>
                    </li>
                )
            else
                return null
        });
    }

    if(brands.data) {
        const related_data = getRelatedData(brand_name, brands.data);
        relatedItems = related_data.map((item: any, index: number) => {
            if (index < 5)
                return (
                    <li key={index}>
                        <a href={"/brands/"+item.id}>
                            <img src={item.image_url} alt={item.name} /><span>{item.name}</span>&nbsp;<em>({item.mails_count} Emails)</em>
                        </a>
                    </li>
                )
            else
                return null
        });
    }

    return (
        <div className={show?"mobile-menu show":"mobile-menu"}>
            <div className='top-companies'>
                <h3>More brands:</h3>
                <ul>
                    {popularItems ? popularItems:""}
                </ul>
            </div>
            <div className='related-brands'>
                <ul>
                    {relatedItems ? relatedItems:""}
                </ul>
            </div>
            <div className='nav-links'>
                <button>
                    <span className="link-icon">
                        <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M8.22857 0.514286C8.22857 0.230254 8.45883 0 8.74286 0C10.5417 0 12 1.45827 12 3.25714C12 3.54118 11.7697 3.77143 11.4857 3.77143C11.2017 3.77143 10.9714 3.54118 10.9714 3.25714C10.9714 2.02634 9.97366 1.02857 8.74286 1.02857C8.45883 1.02857 8.22857 0.798318 8.22857 0.514286Z" fill="#363853"/>
                            <path d="M6 2.4C5.05323 2.4 4.28571 3.16751 4.28571 4.11429C4.28571 5.06106 5.05323 5.82857 6 5.82857C6.94677 5.82857 7.71429 5.06106 7.71429 4.11429C7.71429 3.16751 6.94677 2.4 6 2.4Z" fill="#363853"/>
                            <path d="M4.84434 6.34936C4.02872 6.47953 3.42857 7.18305 3.42857 8.00899V8.13589C3.42857 8.75514 3.93058 9.25714 4.54983 9.25714H7.45017C8.06942 9.25714 8.57143 8.75514 8.57143 8.13589V8.00898C8.57143 7.18305 7.97128 6.47953 7.15566 6.34936C6.39007 6.22717 5.60993 6.22717 4.84434 6.34936Z" fill="#363853"/>
                            <path d="M1.02857 8.74286C1.02857 8.45883 0.798318 8.22857 0.514286 8.22857C0.230254 8.22857 0 8.45883 0 8.74286C0 10.5417 1.45827 12 3.25714 12C3.54118 12 3.77143 11.7697 3.77143 11.4857C3.77143 11.2017 3.54118 10.9714 3.25714 10.9714C2.02634 10.9714 1.02857 9.97366 1.02857 8.74286Z" fill="#363853"/>
                            <path d="M0 3.25714C0 1.45827 1.45827 0 3.25714 0C3.54118 0 3.77143 0.230254 3.77143 0.514286C3.77143 0.798318 3.54118 1.02857 3.25714 1.02857C2.02634 1.02857 1.02857 2.02634 1.02857 3.25714C1.02857 3.54117 0.798318 3.77143 0.514286 3.77143C0.230254 3.77143 0 3.54117 0 3.25714Z" fill="#363853"/>
                            <path d="M12 8.74286C12 8.45883 11.7697 8.22857 11.4857 8.22857C11.2017 8.22857 10.9714 8.45883 10.9714 8.74286C10.9714 9.97366 9.97366 10.9714 8.74286 10.9714C8.45883 10.9714 8.22857 11.2017 8.22857 11.4857C8.22857 11.7697 8.45883 12 8.74286 12C10.5417 12 12 10.5417 12 8.74286Z" fill="#363853"/>
                        </svg>
                    </span>
                    <span>Privacy</span>
                </button>
                <button>
                    <span className="link-icon">
                        <svg width="12" height="13" viewBox="0 0 12 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path fillRule="evenodd" clipRule="evenodd" d="M9.42396 0.768384C7.26409 -0.256128 4.73591 -0.256128 2.57604 0.768384C1.19008 1.4258 0.250806 2.72846 0.0927362 4.21622L0.0680272 4.44878C-0.015146 5.23161 -0.0220673 6.02028 0.0473548 6.80437L0.0705521 7.06637C0.108552 7.49567 0.130473 7.74331 0.173133 7.98C0.404787 9.26527 1.10072 10.4287 2.13614 11.263C2.3268 11.4166 2.53767 11.5584 2.90354 11.8043L3.0917 11.9308C3.57875 12.2583 3.87327 12.4563 4.17429 12.5967C5.32803 13.1344 6.67198 13.1344 7.82571 12.5967C8.12674 12.4563 8.42124 12.2583 8.90832 11.9308L9.09638 11.8044C9.46231 11.5584 9.67318 11.4166 9.86386 11.263C10.8993 10.4287 11.5952 9.26527 11.8269 7.98C11.8695 7.74332 11.8914 7.49568 11.9294 7.06639L11.9526 6.80437C12.0221 6.02028 12.0151 5.23161 11.932 4.44878L11.9073 4.21622C11.7492 2.72846 10.8099 1.4258 9.42396 0.768384ZM8.22784 4.8103C8.4303 4.994 8.44124 5.30249 8.25228 5.49932L5.85228 7.99932C5.7595 8.09597 5.63031 8.15183 5.49435 8.15411C5.35839 8.15639 5.2273 8.10488 5.13115 8.0114L3.75972 6.67807C3.5639 6.48769 3.5639 6.17902 3.75972 5.98864C3.95554 5.79826 4.27302 5.79826 4.46884 5.98864L5.47327 6.96516L7.51914 4.83405C7.70809 4.63723 8.02539 4.62659 8.22784 4.8103Z" fill="#363853"/>
                        </svg>
                    </span>
                    <span>Terms of Use</span>
                </button>
                <button>
                    <span className="link-icon">
                        <svg width="13" height="12" viewBox="0 0 13 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path fillRule="evenodd" clipRule="evenodd" d="M10.6896 3.62283C10.6983 3.6959 10.7064 3.769 10.7139 3.84214C11.7953 3.93263 12.7033 4.70393 12.9348 5.75807L12.9417 5.78962C13.0194 6.14339 13.0194 6.50929 12.9417 6.86306C12.6911 8.00434 11.6602 8.81945 10.4673 8.81945H10.2435C10.2237 8.88586 10.2033 8.95214 10.1824 9.01828L10.0921 9.3039C9.67398 10.6261 8.53583 11.6089 7.1431 11.8502C6.94415 11.8847 6.74441 11.9132 6.54413 11.9357C5.59929 12.0421 4.5317 12.0331 3.56458 11.7811C2.59448 11.5283 1.68305 11.0178 1.142 10.0926C1.11779 10.0512 1.0933 10.0085 1.06841 9.96447C0.836976 9.55515 0.66108 9.12413 0.524072 8.69087C0.0407794 7.16254 -0.110586 5.5522 0.0796938 3.96324L0.160179 3.29114C0.352489 1.68522 1.61801 0.403227 3.25143 0.15965C4.67892 -0.0532172 6.13112 -0.0532168 7.5586 0.159652L7.66539 0.175577C9.23574 0.40975 10.4524 1.64224 10.6373 3.18615L10.6896 3.62283ZM10.4984 7.82189C10.7063 6.8469 10.7998 5.85183 10.7772 4.85608C11.3483 4.96293 11.8127 5.3945 11.9385 5.96757L11.9454 5.99913C11.9928 6.21481 11.9928 6.43787 11.9454 6.65356C11.7979 7.32538 11.1977 7.80799 10.4984 7.82189ZM3.0272 5.26261C3.25232 5.26261 3.43482 5.44121 3.43482 5.66151V5.76351C3.43482 6.54626 3.53292 7.32605 3.72692 8.08543L3.76234 8.22408C3.81694 8.43781 3.68415 8.65438 3.46575 8.70781C3.24734 8.76124 3.02603 8.6313 2.97143 8.41757L2.93601 8.27892C2.72585 7.45626 2.61957 6.61149 2.61957 5.76351V5.66151C2.61957 5.44121 2.80207 5.26261 3.0272 5.26261Z" fill="#363853"/>
                        </svg>
                    </span>
                    <span>Contact</span>
                </button>
            </div>
            <p className="copyright">
                Copyright 2022 Smirk.co
            </p>
        </div>
    );
};

export default MobileMenu;