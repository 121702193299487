import { useBetween } from 'use-between';

import Api from '../api';
import BottomInfo from '../components/bottom-info';
import LowerMenu from '../components/lower-menu';
import SearchBar from '../components/search-bar';
import Topcompanies from '../components/top-companies';

const SearchPage = ({asLoaded,setAsLoaded,tcLoaded, setTcLoaded}:{asLoaded:any, setAsLoaded:any, tcLoaded:any, setTcLoaded:any}) => {
  // eslint-disable-next-line
  const { brandsWithSearchCount, brands, fetchBrandsWithSearchCount, fetchBrands }:{ brandsWithSearchCount:any, brands:any, fetchBrandsWithSearchCount:any, fetchBrands:any } = useBetween(Api);

  let email_count = 0, brands_count = 0, searches_count = 0, new_email = 1.88;
  if(brands.data) {
    brands.data.map((item: any, index: number) => {
      email_count += item.mails_count;
      return email_count;
    });
    brands_count = brands.data.length;
  }

  if(brandsWithSearchCount.data) {
    brandsWithSearchCount.data.map((item: any, index: number) => {
      searches_count += item.brand_searches_count;
      return searches_count;
    });
  }

  return (
    <section className="searh-page">
      <div className="top-section">
        <div className="contant">
          <h1>Welcome To Smirk</h1>
          <h3>Enter a brand to look up their emails</h3>
          <SearchBar brands={brands} fetchBrandsWithSearchCount={fetchBrandsWithSearchCount} />
        </div>
      </div>
         <div className="bottom-section">
          <LowerMenu/>
          <Topcompanies brands={brandsWithSearchCount} tcLoaded={tcLoaded} />
          <BottomInfo email_count={email_count} brands_count={brands_count} searches_count={searches_count} new_email={new_email} />
         </div>
    </section>
  );
};

export default SearchPage;
