import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import AutoSuggest from "react-autosuggest";

const SearchBar = ({brands, fetchBrandsWithSearchCount}:{brands: any, fetchBrandsWithSearchCount: any}) => {
    const [value, setValue] = useState("");
    const [suggestions, setSuggestions] = useState<string[]>([]);
    const [selectBrand, setSelectedBrand] = useState();
    const navigate = useNavigate();

    let companies: any[] = [];
    if(brands.data)
        companies = brands.data;
    
    function escapeRegexCharacters(str: string): string {
        return str.replace(/[.*+?^${}()|[\]\\]/g, "\\$&");
    }

    function getSuggestions(value: string): string[] {
        const escapedValue = escapeRegexCharacters(value.trim());

        if (escapedValue === "") {
            return [];
        }

        return companies.sort((a,b) => {
            a = a.name.toLowerCase();
            b = b.name.toLowerCase();
            let an = a.indexOf(value.toLowerCase());
            let bn = b.indexOf(value.toLowerCase());

            if(an === bn)
                return (a.name > b.name) ? -1 : ((b.name > a.name) ? 1 : 0);
            else
                return  an > bn ? -1 : 1;
        }); 
    }

    function getSuggestionValue(suggestion: any) {
        return suggestion.name;
    }
    
    function renderSuggestion(suggestion: any) {
        return <div onClick={(event: any) => {
            setSelectedBrand(suggestion.id);
            const path = "/brands/" + suggestion.id;
            fetchBrandsWithSearchCount(suggestion.id);
            navigate(path, { replace: true });
        }}><img src={suggestion.image_url} alt="" /><span>{suggestion.name}</span>&nbsp;<em>({suggestion.mails_count} Emails)</em></div>;
    }
    
    function goSearchResult() {
        const path = "/brands/" + selectBrand;
        fetchBrandsWithSearchCount(selectBrand);
        navigate(path, { replace: true });
    }

    return (
        <div className='search-bar'>
            <AutoSuggest
                suggestions={suggestions}
                onSuggestionsClearRequested={() => setSuggestions([])}
                onSuggestionsFetchRequested={({ value }) => {
                    setValue(value);
                    setSuggestions(getSuggestions(value));
                }}
                onSuggestionSelected={(_, { suggestion }) => {
                    if(typeof suggestion === 'object' && suggestion["id"] !== 'undefined' ) {
                        setSelectedBrand(suggestion["id"]);
                    }
                }}
                getSuggestionValue={getSuggestionValue}
                renderSuggestion={renderSuggestion}
                inputProps={{
                    placeholder: "Search for a brand",
                    value: value,
                    onChange: (_, { newValue, method }) => {
                        setValue(newValue);
                    }
                }}
                highlightFirstSuggestion={true}
            />
            <button onClick={goSearchResult}>
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M11 19C15.4183 19 19 15.4183 19 11C19 6.58172 15.4183 3 11 3C6.58172 3 3 6.58172 3 11C3 15.4183 6.58172 19 11 19Z" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                    <path d="M21 20.9999L16.65 16.6499" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                </svg>
                <span>
                    Search
                </span>
            </button>
        </div>
    );
};

export default SearchBar;