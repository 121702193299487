import React from 'react';
import logo from '../assets/img/logo.svg';
const NavBar = ({showMobileMenu, setShowMobileMenu}:{showMobileMenu:any, setShowMobileMenu:any}) => {
  return (
    <nav className="main-nav-bar">
      <a href="/"><img src={logo} alt="logo" className="logo" /></a>
      <button className='menu-trigger-btn' onClick={(event: any) => {
        setShowMobileMenu(!showMobileMenu);
      }}>
        {
          (!showMobileMenu)?(
            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="none" viewBox="0 0 20 20">
              <path d="M12 2a2 2 0 1 1-4 0 2 2 0 0 1 4 0Zm0 8a2 2 0 1 1-4 0 2 2 0 0 1 4 0Zm-2 10a2 2 0 1 0 0-4 2 2 0 0 0 0 4ZM4 10a2 2 0 1 1-4 0 2 2 0 0 1 4 0ZM2 4a2 2 0 1 0 0-4 2 2 0 0 0 0 4Zm2 14a2 2 0 1 1-4 0 2 2 0 0 1 4 0Zm14-6a2 2 0 1 0 0-4 2 2 0 0 0 0 4Zm2-10a2 2 0 1 1-4 0 2 2 0 0 1 4 0Zm-2 18a2 2 0 1 0 0-4 2 2 0 0 0 0 4Z" fill="#393E46" />
            </svg>):(
            <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M12.59 0L7 5.59L1.41 0L0 1.41L5.59 7L0 12.59L1.41 14L7 8.41L12.59 14L14 12.59L8.41 7L14 1.41L12.59 0Z" fill="black"/>
            </svg>)
        }
      </button>
    </nav>
  );
};

export default NavBar;
