import React, {useState } from 'react';
import DateRangePicker from 'react-daterange-picker';
import moment from './moment-range';
import MailFilterButton from './mail-filter-button';
import 'react-daterange-picker/dist/css/react-calendar.css';

const MailFilter = ({dateRange, setDateRange, showFilterModal, setShowFilterModal, setPage}:{dateRange:any, setDateRange:any, showFilterModal:any, setShowFilterModal:any, setPage:any}) => {
    const [selectedDateRange, setSelectedDateRange] = useState();
    const [selectedButton, setSelectedButton] = useState(-1);
    const today = moment();

    const dateRanges = {
        'All Time': moment.range(
            today.clone().subtract(100, 'years'),
            today.clone()
        ),
        'Today': moment.range(
            today.clone(),
            today.clone()
        ),
        'Yesterday': moment.range(
            today.clone().subtract(1, 'days'),
            today.clone().subtract(1, 'days')
          ),
        'Last 7 days': moment.range(
          today.clone().subtract(7, 'days'),
          today.clone()
        ),
        'Last 30 days': moment.range(
          today.clone().subtract(30, 'days'),
          today.clone()
        ),
        'This Month': moment.range(
          today.clone().startOf('month'),
          today.clone()
        ),
        'This Year': moment.range(
          today.clone().startOf('year'),
          today.clone()
        )
    };

    const handleSelect = (dates:any) => {
        setSelectedDateRange(dates);
        setSelectedButton(-1);
    }

    const handleFilter = () => {
        setDateRange(selectedDateRange);
        setPage(1);
    }

    const handleClose = () => {
        setShowFilterModal(0);
    }

    return (
        <div className={showFilterModal?'mail-filter active':'mail-filter'}>
            <MailFilterButton dateRanges={dateRanges} setSelectedDateRange={setSelectedDateRange} selectedButton={selectedButton} setSelectedButton={setSelectedButton} />
            <DateRangePicker onSelect={handleSelect} value={selectedDateRange} />
            <div className='buttons'>
                <button className='filter-button' onClick={(event: any) => {
                    handleFilter();
                    handleClose();
                }}>Filter</button>
                <button className='close-button' onClick={(event: any) => {
                    handleClose()
                }}>Cancel</button>
            </div>
        </div>
    );
};

export default MailFilter;